import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, NamedLink } from '../../components';

import css from './ListingPage.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';

const { Money } = sdkTypes;

const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingConfig, intl } = props;
  const productCategory = publicData.category;

  const { listingFields } = listingConfig || {};

  if (!publicData || !listingConfig) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];

    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;
    if (isDetail && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);
      return key === 'category'
        ? filteredConfigs.concat({ key, value: optionConfig?.option, label })
        : key === 'brand' || key === 'model'
        ? filteredConfigs.concat({ key, value, label })
        : key === 'estimatedValue'
        ? filteredConfigs.concat({
            key,
            value: formatMoney(intl, new Money(value.amount, value.currency)),
            label,
          })
        : // : schemaType === 'boolean'
          //   ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
          //   : schemaType === 'long'
          //     ? filteredConfigs.concat({ key, value, label })
          filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFields.reduce(pickListingFields, []);

  existingListingFields.map(item => {
    if (item?.key === 'category') {
      item.value = productCategory;
    }
  });

  return existingListingFields.length > 0 ? (
    <div className={css.sectionDetails}>
      {/* <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading> */}
      <ul className={css.details}>
        {existingListingFields.map(detail => {
          return (
            <li key={detail.key} className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id={`ListingPage.${detail.label}Label`} />
              </span>
              <span>
                {detail.key === 'category' ? (
                  <NamedLink
                    className={css.reviewsLink}
                    name="SearchPage"
                    to={{ search: `pub_category=${detail.value?.toLowerCase()}&sort=relevance` }}
                  >
                    <FormattedMessage
                      id={`ListingPage.${detail.label}.${detail.value?.toLowerCase()}`}
                    />
                  </NamedLink>
                ) : detail.key === 'estimatedValue' ? (
                  <span className={css.detailsEstimatedValue}>{detail.value}</span>
                ) : (
                  detail.value
                )}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};

export default SectionDetailsMaybe;
