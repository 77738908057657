import React, { useState } from 'react';
import { string, oneOfType, bool } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { NamedLink } from '..';

import css from './Rating.module.css';
import IconStar from '../IconStar/IconStar';

const RatingComponent = ({ listing, author, small = false }) => {
  const [averageRating, setAverageRating] = useState(null);

  React.useEffect(() => {
    if (listing) {
      const averageRating = listing?.attributes?.metadata?.rating || {};
      setAverageRating(averageRating);
    }
    if (author) {
      const averageRating = author?.attributes?.profile?.metadata?.ofProviderRating || {};
      setAverageRating(averageRating);
    }
  }, [listing]);

  const ratings = typeof averageRating?.avg === 'number' ? (
    <span className={css.root}>
      <IconStar className={css.iconStar} />
      <b>{averageRating.avg.toFixed(2)}</b>
      {!small ? <FormattedMessage id="OrderPanel.reviews" values={{ count: averageRating.count }} /> : null}
    </span>
  ) : (
    <span className={css.root}>
      <IconStar className={css.iconStar} />
      <b>{small ? '-,-' : <FormattedMessage id="OrderPanel.noReviews" />}</b>
    </span>
  );

  return ratings;
};
const Rating = injectIntl(RatingComponent);
export default Rating;